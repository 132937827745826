import React, { useContext, useEffect, useReducer, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import Archive from '@material-ui/icons/Archive';
import FindReplaceIcon from '@material-ui/icons/FindReplace';
import SearchIcon from '@material-ui/icons/Search';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import CircularProgress from '@mui/material/CircularProgress';

import IconButton from '@material-ui/core/IconButton';
import BlockIcon from '@material-ui/icons/Block';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';

import ConfirmationModal from '../../components/ConfirmationModal/';
import ContactModal from '../../components/ContactModal';
import TableRowSkeleton from '../../components/TableRowSkeleton';
import api from '../../services/api';

import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import { Can } from '../../components/Can';
import ContactsExport from '../../components/ContactsExport';
import MainContainer from '../../components/MainContainer';
import NewTicketModal from '../../components/NewTicketModal';
import { AuthContext } from '../../context/Auth/AuthContext';
import toastError from '../../errors/toastError';
import { i18n } from '../../translate/i18n';

import { AccordionSummary, Chip, FormControlLabel, Grid, Menu, MenuItem, Accordion as MuiAccordion, AccordionDetails as MuiAccordionDetails, Tooltip, styled } from '@material-ui/core';
import { ArrowDropDown, Backup, ContactPhone } from '@material-ui/icons';
import { Autocomplete } from '@mui/material';
import ContactExtraInfoModal from '../../components/ContactExtraInfoModal';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import { formatDocument } from '../../helpers/documentHelper';
import socket from '../../hooks/useSocket';
import UFsBrasil from '../../utils/UFsBrasil';
import isColorDark from '../../utils/isColorDark';

import FilterListIcon from '@material-ui/icons/FilterList';
import DateRangePopover from '../../components/DateRangePopover';
import EstadosBrasil from '../../utils/EstadosBrasil';


const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&::before': {
        display: 'none',
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const reducer = (state, action) => {
    if (action.type === 'LOAD_CONTACTS') {
        const contacts = action.payload;
        const newContacts = [];

        contacts.forEach((contact) => {
            const contactIndex = state.findIndex((c) => c.id === contact.id);
            if (contactIndex !== -1) {
                state[contactIndex] = contact;
            } else {
                newContacts.push(contact);
            }
        });

        return [...state, ...newContacts];
    }

    if (action.type === 'UPDATE_CONTACTS') {
        const contact = action.payload;
        const contactIndex = state.findIndex((c) => c.id === contact.id);

        if (contactIndex !== -1) {
            state[contactIndex] = contact;
            return [...state];
        } else {
            return [contact, ...state];
        }
    }

    if (action.type === 'DELETE_CONTACT') {
        const contactId = action.payload;

        const contactIndex = state.findIndex((c) => c.id === contactId);
        if (contactIndex !== -1) {
            state.splice(contactIndex, 1);
        }
        return [...state];
    }

    if (action.type === 'RESET') {
        return [];
    }
};

const useStyles = makeStyles((theme) => ({
    mainPaper: {
        flex: 1,
        padding: theme.spacing(1),
        overflowY: 'scroll',
        ...theme.scrollbarStyles,
    },
    exporte: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50 %, -50 %)',
        backgroundColor: 'white',
        padding: '20px',
        boxShadow: '0 0 20px rgba(0, 0, 0, 0.2)',
        zIndex: 0,
        width: '235px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));

const initialLoading = {
    fetch: false,
    tags: false
};

const Contacts = () => {
    const classes = useStyles();
    const history = useHistory();

    const { user } = useContext(AuthContext);

    const theme = useTheme();

    const [loading, setLoading] = useState(initialLoading);
    const [pageNumber, setPageNumber] = useState(1);
    const [searchParam, setSearchParam] = useState('');
    const [contacts, dispatch] = useReducer(reducer, []);

    const [tagIds, setTagIds] = useState(null);
    const [tags, setTags] = useState([]);

    const [selectedContactId, setSelectedContactId] = useState(null);
    const [contactModalOpen, setContactModalOpen] = useState(false);
    const [contactInfoModalOpen, setContactInfoModalOpen] = useState(false);
    const [deletingContact, setDeletingContact] = useState(null);
    const [ImportContacts, setImportContacts] = useState(null);
    const [blockingContact, setBlockingContact] = useState(null);
    const [unBlockingContact, setUnBlockingContact] = useState(null);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [confirmChatsOpen, setConfirmChatsOpen] = useState(false);
    const [hasMore, setHasMore] = useState(false);
    const [newTicketModalOpen, setNewTicketModalOpen] = useState(false);
    const [contactTicket, setContactTicket] = useState({});
    const fileUploadRef = useRef(null);
    const [showPopup, setShowPopup] = useState(false);
    const [totalContacts, setTotalContacts] = useState(0);
    const [, setQueue] = useState('');

    const [filters, setFilters] = useState([
        { type: "email", filter: false },
        { type: "document", filter: false }
    ]);

    // Additional filter states (e.g., date, location)
    const [date, setDate] = useState(null);
    const [createdAt, setCreatedAt] = useState(null);
    const [state, setState] = useState(null);
    const [city, setCity] = useState(null);
    const [cities, setCities] = useState([]);

    const [expanded, setExpanded] = useState(false);

    useEffect(() => {
        if (state?.code) {
            const { cidades } = EstadosBrasil.find(estado => estado.sigla === state.code);
            setCities(cidades)
        }
    }, [state])


    const handleImportExcel = async () => {
        try {
            const formData = new FormData();
            formData.append('file', fileUploadRef.current.files[0]);
            await api.request({
                url: `/contacts/upload`,
                method: 'POST',
                data: formData,
            });
            history.go(0);
        } catch (err) {
            toastError(err);
        }
    };

    const togglePopup = () => {
        setShowPopup(!showPopup);
    };

    const handleFilter = (event) => {
        event.preventDefault();
        // Filtra os tickets por fila
    };

    const handleQueueChange = (event) => {
        setQueue(event.target.value);
    };

    useEffect(() => {
        dispatch({ type: 'RESET' });
        setPageNumber(1);
    }, [searchParam]);

    useEffect(() => {
        setLoading(true);
        const delayDebounceFn = setTimeout(() => {
            const fetchContacts = async () => {
                try {
                    const { data } = await api.get('/contacts/', {
                        params: { searchParam, pageNumber },
                    });
                    setTotalContacts(data.count || 0);
                    dispatch({ type: 'LOAD_CONTACTS', payload: data.contacts });
                    setHasMore(data.hasMore);
                } catch (err) {
                    toastError(err);
                } finally {
                    setLoading(false);
                }
            };
            fetchContacts();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [searchParam, pageNumber]);

    useEffect(() => {
        const fetchTags = async () => {
            try {
                setLoading((prevLoading) => ({ ...prevLoading, tags: true }));
                const { data } = await api.get(`/tags/list`, { params: { type: "contacts" } });
                setTags(data);
            } catch (err) {
                toastError(err);
            } finally {
                setLoading((prevLoading) => ({ ...prevLoading, tags: false }));
            }
        }
        fetchTags();
    }, []);

    const handleChangeChecked = (event) => {
        const { name, checked } = event.target;

        setFilters((prevFilters) =>
            prevFilters.map((filter) =>
                filter.type === name ? { ...filter, filter: checked } : filter
            )
        );
    };

    useEffect(() => {
        const companyId = localStorage.getItem('companyId');

        socket.on(`company-${companyId}-contact`, (data) => {
            if (data.action === 'update' || data.action === 'create') {
                dispatch({ type: 'UPDATE_CONTACTS', payload: data.contact });
            }

            if (data.action === 'delete') {
                dispatch({ type: 'DELETE_CONTACT', payload: +data.contactId });
            }
        });

        return () => { };
    }, []);

    const handleCloseOrOpenTicket = (ticket) => {
        setNewTicketModalOpen(false);
        if (ticket !== undefined && ticket.uuid !== undefined) {
            history.push(`/tickets/${ticket.uuid}`);
        }
    };

    const handleSearch = (event) => {
        setSearchParam(event.target.value.toLowerCase());
    };

    const handleOpenContactInfoModal = () => {
        setContactInfoModalOpen(true)
    }

    const handleCloseContactInfoModal = () => {
        setContactInfoModalOpen(false)
    }

    const handleOpenContactModal = () => {
        setSelectedContactId(null);
        setContactModalOpen(true);
    };

    const handleCloseContactModal = () => {
        setSelectedContactId(null);
        setContactModalOpen(false);
    };

    const hadleEditContact = (contactId) => {
        setSelectedContactId(contactId);
        setContactModalOpen(true);
    };

    const handleDeleteContact = async (contactId) => {
        try {
            await api.delete(`/contacts/${contactId}`);
            toast.success(i18n.t('contacts.toasts.deleted'));
        } catch (err) {
            toastError(err);
        }
        setDeletingContact(null);
        setSearchParam('');
        setPageNumber(1);
    };

    const handleBlockContact = async (contactId) => {
        try {
            await api.put(`/contacts/block/${contactId}`, { active: false });
            toast.success('Contato bloqueado');
        } catch (err) {
            toastError(err);
        }
        setDeletingContact(null);
        setSearchParam('');
        setPageNumber(1);
    };

    const handleUnBlockContact = async (contactId) => {
        try {
            await api.put(`/contacts/block/${contactId}`, { active: true });
            toast.success('Contato desbloqueado');
        } catch (err) {
            toastError(err);
        }
        setDeletingContact(null);
        setSearchParam('');
        setPageNumber(1);
    };

    const handleimportContact = async () => {
        try {
            await api.post('/contacts/import');
            history.go(0);
            setImportContacts(false);
        } catch (err) {
            toastError(err);
            setImportContacts(false);
        }
    };

    const handleimportChats = async () => {
        try {
            await api.post('/contacts/import/chats');
            history.go(0);
        } catch (err) {
            toastError(err);
        }
    };

    const loadMore = () => {
        setPageNumber((prevState) => prevState + 1);
    };

    const handleScroll = (e) => {
        if (!hasMore || loading.fetch) return;
        const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
        if (scrollHeight - (scrollTop + 100) < clientHeight) {
            loadMore();
        }
    };

    function getDateLastMessage(contact) {
        if (!contact) return null;
        if (!contact.tickets) return null;

        if (contact.tickets.length > 0) {
            const date = new Date(contact.tickets[contact.tickets.length - 1].updatedAt);

            const day = (date.getDate()).toString().padStart(2, '0');
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const year = (date.getFullYear()).toString().padStart(2, '0');

            const hours = (date.getHours()).toString().padStart(2, '0');
            const minutes = (date.getMinutes()).toString().padStart(2, '0');

            return `${day}/${month}/${year} ${hours}:${minutes}`;
        }

        return null;
    }

    const handleClickOpenPanel = () => {
        setExpanded(!expanded);
    };

    const handleChangeTags = (event, value) => {
        const selectedIds = value.map((tag) => tag.id);
        setTagIds(selectedIds);
    };

    useEffect(() => {
        setLoading(true);
        const delayDebounceFn = setTimeout(() => {
            const fetchContacts = async () => {
                try {
                    const { data } = await api.get('/contacts/', {
                        params: { searchParam, pageNumber },
                    });
                    setTotalContacts(data.count || 0);
                    dispatch({ type: 'LOAD_CONTACTS', payload: data.contacts });
                    setHasMore(data.hasMore);
                } catch (err) {
                    toastError(err);
                } finally {
                    setLoading(false);
                }
            };
            fetchContacts();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [searchParam, pageNumber]);


    const fetchFilters = async () => {
        try {
            setLoading((prevLoading) => ({ ...prevLoading, fetch: true }));
            const { data } = await api.get("/contacts/", {
                params: {
                    searchParam,
                    pageNumber,
                    filters: {
                        email: filters.find((f) => f.type === "email")?.filter || false,
                        document: filters.find((f) => f.type === "document")?.filter || false,
                        tagIds,
                        state: state?.code,
                        city,
                        date,
                        createdAt,
                    },
                },
            });

            dispatch({ type: "RESET" });
            await new Promise(r => setTimeout(r, 250));
            dispatch({ type: "LOAD_CONTACTS", payload: data.contacts });
            setHasMore(data.hasMore);
        } catch (err) {
            toastError(err);
        } finally {
            setLoading((prevLoading) => ({ ...prevLoading, fetch: false }));
        }
    }

    return (
        <MainContainer className={classes.mainContainer}>
            {showPopup && (
                <ContactsExport
                    className={classes.exporte}
                    handleClose={togglePopup}
                    handleFilter={handleFilter}
                    handleQueueChange={handleQueueChange}
                />
            )}
            <ContactExtraInfoModal
                open={contactInfoModalOpen}
                onClose={handleCloseContactInfoModal}
            />
            <NewTicketModal
                modalOpen={newTicketModalOpen}
                initialContact={contactTicket}
                onClose={(ticket) => {
                    handleCloseOrOpenTicket(ticket);
                }}
            />
            <ContactModal
                open={contactModalOpen}
                onClose={handleCloseContactModal}
                aria-labelledby="form-dialog-title"
                contactId={selectedContactId}
            ></ContactModal>
            <ConfirmationModal
                title={
                    deletingContact
                        ? `${i18n.t('contacts.confirmationModal.deleteTitle')} ${deletingContact.name}?`
                        : blockingContact
                            ? `Bloquear Contato ${blockingContact.name}?`
                            : unBlockingContact
                                ? `Desbloquear Contato ${unBlockingContact.name}?`
                                : ImportContacts
                                    ? `${i18n.t('contacts.confirmationModal.importTitlte')}`
                                    : `${i18n.t('contactListItems.confirmationModal.importTitlte')}`
                }
                open={confirmOpen}
                onClose={setConfirmOpen}
                onConfirm={() =>
                    deletingContact
                        ? handleDeleteContact(deletingContact.id)
                        : blockingContact
                            ? handleBlockContact(blockingContact.id)
                            : unBlockingContact
                                ? handleUnBlockContact(unBlockingContact.id)
                                : ImportContacts
                                    ? handleimportContact()
                                    : handleImportExcel()
                }
            >
                {deletingContact
                    ? `${i18n.t('contacts.confirmationModal.deleteMessage')}`
                    : blockingContact
                        ? `Tem certeza que deseja bloquear este contato?`
                        : unBlockingContact
                            ? `Tem certeza que deseja desbloquear este contato?`
                            : ImportContacts
                                ? `${i18n.t('contacts.confirmationModal.importMessage')}`
                                : `${i18n.t('contactListItems.confirmationModal.importMessage')}`}
            </ConfirmationModal>
            <ConfirmationModal
                title={'Importar Conversas'}
                open={confirmChatsOpen}
                onClose={setConfirmChatsOpen}
                onConfirm={() => handleimportChats()}
            >
                Deseja importar todas as conversas do telefone?
            </ConfirmationModal>
            <Box>
                <Grid container spacing={2} alignItems="center" justifyContent="flex-end">
                    {/* Título */}
                    <Grid item xs={12}>
                        <HeaderBreadcrumbs
                            isDark={theme.mode === "dark"}
                            heading={`${i18n.t("contacts.title")}${totalContacts > 0 ? ` ${contacts.length}/${totalContacts}` : ""}`}
                            action={
                                <Box style={{ display: 'flex', gap: '5px' }}>
                                    {/* Botão de Adicionar */}
                                    <Button
                                        size="small"
                                        variant="contained"
                                        color="primary"
                                        startIcon={<AddIcon />}
                                        onClick={handleOpenContactModal}
                                    >
                                        {i18n.t("contacts.buttons.add")}
                                    </Button>
                                    {/* Botão Importar / Exportar */}
                                    <Grid item>
                                        <PopupState variant="popover" popupId="importExportMenu">
                                            {(popupState) => (
                                                <>
                                                    <Button
                                                        {...bindTrigger(popupState)}
                                                        size="small"
                                                        variant="contained"
                                                        color="primary"
                                                    >
                                                        Importar / Exportar
                                                        <ArrowDropDown />
                                                    </Button>
                                                    <Menu {...bindMenu(popupState)}>
                                                        <MenuItem
                                                            onClick={() => {
                                                                setConfirmOpen(true);
                                                                setImportContacts(true);
                                                            }}
                                                        >
                                                            <ContactPhone fontSize="small" color="primary" />
                                                            Agenda do Telefone
                                                        </MenuItem>
                                                        <MenuItem
                                                            onClick={() => {
                                                                fileUploadRef.current.value = null;
                                                                fileUploadRef.current.click();
                                                            }}
                                                        >
                                                            <Backup fontSize="small" color="primary" />
                                                            Importar CSV
                                                        </MenuItem>
                                                        <MenuItem onClick={togglePopup}>
                                                            <Archive fontSize="small" color="primary" />
                                                            Exportar CSV
                                                        </MenuItem>
                                                    </Menu>
                                                </>
                                            )}
                                        </PopupState>
                                    </Grid>
                                    {/* Botão Informações (Somente Admin) */}
                                    {user?.profile === "admin" && (
                                        <Grid item>
                                            <Button
                                                size="small"
                                                variant="contained"
                                                color="primary"
                                                onClick={handleOpenContactInfoModal}
                                            >
                                                {i18n.t("contacts.buttons.info")}
                                            </Button>
                                        </Grid>
                                    )}
                                </Box>
                            }
                        />
                    </Grid>
                    {/* Filtros */}
                    <Box style={{ width: '100%' }}>
                        <Accordion expanded={expanded}>
                            <AccordionSummary
                                aria-controls="panel1d-content"
                                id="panel1d-header"
                            >
                                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                                    <TextField
                                        variant='outlined'
                                        fullWidth
                                        style={{ maxWidth: '300px' }}
                                        size="small"
                                        type="search"
                                        placeholder={i18n.t("contacts.searchPlaceholder")}
                                        value={searchParam}
                                        onChange={handleSearch}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <SearchIcon style={{ color: "gray" }} />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <Tooltip title="Ver Filtros">
                                        <IconButton
                                            aria-label="delete"
                                            color="primary"
                                            onClick={() => handleClickOpenPanel()}
                                        >
                                            <FilterListIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box style={{ width: '100%' }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={3}>
                                            <DateRangePopover onDateChange={console.info} label="Data de criação" dates={setCreatedAt} />
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <Autocomplete
                                                fullWidth
                                                disabled={loading.fetch}
                                                id="state-autocomplete"
                                                size="small"
                                                options={[
                                                    ...UFsBrasil.sudeste,
                                                    ...UFsBrasil.norte,
                                                    ...UFsBrasil.nordeste,
                                                    ...UFsBrasil.centro_oeste,
                                                    ...UFsBrasil.sul
                                                ]} // Junta todos os estados em uma única lista
                                                groupBy={(option) => {
                                                    if (UFsBrasil.sudeste.includes(option)) return "Sudeste";
                                                    if (UFsBrasil.norte.includes(option)) return "Norte";
                                                    if (UFsBrasil.nordeste.includes(option)) return "Nordeste";
                                                    if (UFsBrasil.centro_oeste.includes(option)) return "Centro-Oeste";
                                                    if (UFsBrasil.sul.includes(option)) return "Sul";
                                                }}
                                                getOptionLabel={(option) => `${option.code} - ${option.name}`}
                                                value={state}
                                                onChange={(event, newValue) => {
                                                    setCities([])
                                                    setState(newValue);
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label={i18n.t('contactModal.form.state')}
                                                        placeholder="Selecione um estado"
                                                        variant="outlined"
                                                        InputLabelProps={{ shrink: true }}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            style: {
                                                                height: 40,
                                                                padding: '0 8px',
                                                            },
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <Autocomplete
                                                fullWidth
                                                disabled={loading.fetch || !state?.code}
                                                id="city-autocomplete"
                                                size="small"
                                                options={cities}
                                                getOptionLabel={(option) => option}
                                                value={city || null}
                                                onChange={(event, newValue) => {
                                                    setCity(newValue);
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label={i18n.t('contactModal.form.city')}
                                                        placeholder="Selecione uma cidade"
                                                        variant="outlined"
                                                        InputLabelProps={{ shrink: true }}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            style: {
                                                                height: 40,
                                                                padding: '0 8px',
                                                            },
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <DateRangePopover onDateChange={console.info} label="Data de Nascimento / Abertura" dates={setDate} />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Autocomplete
                                                fullWidth
                                                InputLabelProps={{ shrink: true }}
                                                multiple
                                                disabled={loading.fetch}
                                                size="small"
                                                id="tags-outlined"
                                                options={tags}
                                                limitTags={3}
                                                getOptionLabel={(option) => option.name}
                                                filterSelectedOptions
                                                value={(tags || []).filter(tag => tagIds?.includes(tag.id)) || []}
                                                onChange={handleChangeTags}
                                                renderTags={(selectedTags, getTagProps) =>
                                                    selectedTags.map((option, index) => (
                                                        <Chip
                                                            key={option.id}
                                                            label={option.name}
                                                            size="small"
                                                            style={{
                                                                borderRadius: '7px',
                                                                backgroundColor: option.color,
                                                                color: isColorDark(option.color) ? '#FFFFFF' : '#000000',
                                                                '& .MuiChip-deleteIcon': {
                                                                    color: isColorDark(option.color) ? '#FFFFFF' : '#000000',
                                                                }
                                                            }}
                                                            {...getTagProps({ index })}
                                                            variant="outlined"
                                                        />
                                                    ))
                                                }
                                                renderInput={(params) => (
                                                    <TextField
                                                        InputLabelProps={{ shrink: true }}
                                                        {...params}
                                                        fullWidth
                                                        label="Tags"
                                                        placeholder="Selecione tags de contato"
                                                        variant="outlined"
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            style: {
                                                                height: 40,
                                                                padding: '0 8px',
                                                            },
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <Box
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'space-around'
                                                }}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={filters.find((f) => f.type === "document")?.filter || false}
                                                            onChange={handleChangeChecked}
                                                            name="document"
                                                        />
                                                    }
                                                    label="Com Documento"
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={filters.find((f) => f.type === "email")?.filter || false}
                                                            onChange={handleChangeChecked}
                                                            name="email"
                                                        />
                                                    }
                                                    label="Com Email"
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={2}>
                                            <Button disabled={loading.fetch} fullWidth variant="outlined" color="secondary" onClick={() => fetchFilters()} endIcon={loading.fetch ? <CircularProgress color="inherit" size={20} /> : <FindReplaceIcon />}>Aplicar filtros</Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    </Box>
                </Grid>
            </Box >


            <Paper className={classes.mainPaper} variant="outlined" onScroll={handleScroll}>
                <>
                    <input
                        style={{ display: 'none' }}
                        id="upload"
                        name="file"
                        type="file"
                        accept=".xls,.xlstyle"
                        onChange={() => {
                            setConfirmOpen(true);
                        }}
                        ref={fileUploadRef}
                    />
                </>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell padding="checkbox" />
                            <TableCell>{i18n.t('contacts.table.name')}</TableCell>
                            <TableCell align="center">{i18n.t('contacts.table.whatsapp')}</TableCell>
                            <TableCell align="center">{i18n.t('contacts.table.email')}</TableCell>
                            <TableCell align="center">{i18n.t('contacts.table.document')}</TableCell>
                            <TableCell align="center">{'Última Mensagem'}</TableCell>
                            <TableCell align="center">{'Status'}</TableCell>
                            <TableCell align="center">{i18n.t('contacts.table.actions')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <>
                            {contacts.map((contact) => (
                                <TableRow key={contact.id}>
                                    <TableCell style={{ paddingRight: 0 }}>
                                        {<Avatar src={contact.profilePicUrl} />}
                                    </TableCell>
                                    <TableCell>{contact.name}</TableCell>
                                    <TableCell align="center">{contact.number}</TableCell>
                                    <TableCell align="center">{contact.email}</TableCell>
                                    <TableCell align="center">{formatDocument(contact.document)}</TableCell>
                                    <TableCell align="center">{getDateLastMessage(contact)}</TableCell>
                                    <TableCell align="center">
                                        {contact.active ? (
                                            <CheckCircleIcon style={{ color: 'green' }} fontSize="small" />
                                        ) : (
                                            <CancelIcon style={{ color: 'red' }} fontSize="small" />
                                        )}
                                    </TableCell>
                                    <TableCell align="center">
                                        <IconButton
                                            size="small"
                                            onClick={() => {
                                                setContactTicket(contact);
                                                setNewTicketModalOpen(true);
                                                // handleSaveTicket(contact.id);
                                            }}
                                        >
                                            <WhatsAppIcon color="secondary" />
                                        </IconButton>

                                        <IconButton size="small" onClick={() => hadleEditContact(contact.id)}>
                                            <EditIcon color="secondary" />
                                        </IconButton>
                                        <IconButton
                                            size="small"
                                            onClick={
                                                contact.active
                                                    ? () => {
                                                        setConfirmOpen(true);
                                                        setBlockingContact(contact);
                                                    }
                                                    : () => {
                                                        setConfirmOpen(true);
                                                        setUnBlockingContact(contact);
                                                    }
                                            }
                                        >
                                            {contact.active ? (
                                                <BlockIcon color="secondary" />
                                            ) : (
                                                <CheckCircleIcon color="secondary" />
                                            )}
                                        </IconButton>
                                        <Can
                                            role={user.profile}
                                            perform="contacts-page:deleteContact"
                                            yes={() => (
                                                <IconButton
                                                    size="small"
                                                    onClick={() => {
                                                        setConfirmOpen(true);
                                                        setDeletingContact(contact);
                                                    }}
                                                >
                                                    <DeleteOutlineIcon color="secondary" />
                                                </IconButton>
                                            )}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                            {loading.fetch && <TableRowSkeleton avatar columns={6} />}
                        </>
                    </TableBody>
                </Table>
            </Paper>
        </MainContainer >
    );
};

export default Contacts;