import React, { useContext, useEffect, useRef, useState } from 'react';

import { Field, Form, Formik } from 'formik';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import { green } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { head } from 'lodash';
import { AuthContext } from '../../context/Auth/AuthContext';
import toastError from '../../errors/toastError';
import api from '../../services/api';
import { i18n } from '../../translate/i18n';
import MessageVariablesPicker, { messageVariables } from '../MessageVariablesPicker';

import { FormControl, Grid, InputLabel, MenuItem, Select } from '@material-ui/core';
import ConfirmationModal from '../ConfirmationModal';

const path = require('path');

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    multFieldLine: {
        display: 'flex',
        '& > *:not(:last-child)': {
            marginRight: theme.spacing(1),
        },
    },

    btnWrapper: {
        position: 'relative',
    },

    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    colorAdorment: {
        width: 20,
        height: 20,
    },
}));

const QuickeMessageSchema = Yup.object().shape({
    shortcode: Yup.string().required('Obrigatório'),
    message: Yup.string().required('Obrigatório'),
});

const QuickMessageDialog = ({ open, onClose, quickemessageId, reload, showType }) => {
    const classes = useStyles();
    const { user } = useContext(AuthContext);
    const { profile } = user;
    const messageInputRef = useRef();

    const initialState = {
        shortcode: '',
        message: '',
        geral: false,
        status: true,
        type: 'COMMON',
    };

    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const [quickemessage, setQuickemessage] = useState(initialState);
    const [attachment, setAttachment] = useState(null);
    const attachmentFile = useRef(null);

    useEffect(() => {
        try {
            (async () => {
                if (!quickemessageId) return;

                const { data } = await api.get(`/quick-messages/${quickemessageId}`);
                setQuickemessage((prevState) => {
                    return { ...prevState, ...data };
                });
            })();
        } catch (err) {
            toastError(err);
        }
    }, [quickemessageId, open]);

    const handleClose = () => {
        setQuickemessage(initialState);
        setAttachment(null);
        onClose();
    };

    const handleAttachmentFile = (e) => {
        const file = head(e.target.files);

        const MAX_FILE_SIZE = +process.env.MAX_FILE_SIZE || 150 * 1024 * 1024; // 150MB

        if (file && file.size > MAX_FILE_SIZE) {
            toastError(`O arquivo selecionado é maior que ${+process.env.MAX_FILE_SIZE || 150}MB. Por favor, selecione outro arquivo.`);
            return;
        }

        setAttachment(file);
    };

    const handleSaveQuickeMessage = async (values) => {
        const quickemessageData = {
            ...values,
            isMedia: true,
            mediaPath: attachment ? attachment.name : values.mediaPath ? path.basename(values.mediaPath) : null,
        };
        try {
            if (quickemessageId) {
                await api.put(`/quick-messages/${quickemessageId}`, quickemessageData);
                if (attachment != null) {
                    const formData = new FormData();
                    formData.append('typeArch', 'quickMessage');
                    formData.append('file', attachment);
                    await api.post(`/quick-messages/${quickemessageId}/media-upload`, formData);
                }
            } else {
                const { data } = await api.post('/quick-messages', quickemessageData);
                if (attachment != null) {
                    const formData = new FormData();
                    formData.append('typeArch', 'quickMessage');
                    formData.append('file', attachment);
                    await api.post(`/quick-messages/${data.id}/media-upload`, formData);
                }
            }
            toast.success(i18n.t('quickemessage.toasts.success'));
            if (typeof reload == 'function') {
                reload();
            }
        } catch (err) {
            toastError(err);
        }
        handleClose();
    };

    const deleteMedia = async () => {
        if (attachment) {
            setAttachment(null);
            attachmentFile.current.value = null;
        }

        if (quickemessage.mediaPath) {
            await api.delete(`/quick-messages/${quickemessage.id}/media-upload`);
            setQuickemessage((prev) => ({
                ...prev,
                mediaPath: null,
            }));
            toast.success(i18n.t('quickemessage.toasts.deleted'));
            if (typeof reload == 'function') {
                console.log(reload);
                console.log('1');
                reload();
            }
        }
    };

    const handleClickMsgVar = async (msgVar, setValueFunc) => {
        const el = messageInputRef.current;
        const firstHalfText = el.value.substring(0, el.selectionStart);
        const secondHalfText = el.value.substring(el.selectionEnd);
        const newCursorPos = el.selectionStart + msgVar.length;

        setValueFunc('message', `${firstHalfText}${msgVar}${secondHalfText}`);

        await new Promise((r) => setTimeout(r, 100));
        messageInputRef.current.setSelectionRange(newCursorPos, newCursorPos);
    };

    const QuickMessageForm = ({ values, touched, errors, isSubmitting, setFieldValue }) => {
        useEffect(() => {
            const { message } = values;
            const hasDisabledVar = messageVariables
                .filter((msgVar) => message.includes(msgVar.value))
                .some((msgVar) => !msgVar.enabledForType.includes(values.type));

            if (hasDisabledVar) {
                toastError(i18n.t('quickemessage.toasts.disabledVar'), 4000);
                setFieldValue('message', message.replace(/{{.*?}}/g, ''));
            }

            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [values.type]);

        return (
            <Form>
                <DialogContent dividers>
                    <Grid spacing={2} container>
                        <Grid xs={12} item>
                            <Field
                                as={TextField}
                                label={i18n.t('quickemessage.dialog.form.shortcode')}
                                name="shortcode"
                                error={touched.shortcode && Boolean(errors.shortcode)}
                                helperText={touched.shortcode && errors.shortcode}
                                variant="outlined"
                                margin="dense"
                                fullWidth
                            />
                        </Grid>
                        {showType && (
                            <Grid xs={12} item>
                                <FormControl variant="outlined" margin="dense" fullWidth>
                                    <InputLabel id="type-selection-label">
                                        {i18n.t('quickemessage.dialog.form.type')}
                                    </InputLabel>
                                    <Field
                                        as={Select}
                                        label={i18n.t('quickemessage.dialog.form.type')}
                                        placeholder={i18n.t('quickemessage.dialog.form.type')}
                                        labelId="type-selection-label"
                                        id="type"
                                        name="type"
                                        error={touched.type && Boolean(errors.type)}
                                    >
                                        {['COMMON', 'EMAIL_FILTER_COMMENT'].map((type) => (
                                            <MenuItem key={type} value={type}>
                                                {i18n.t(`quickemessages.table.type.${type}`)}
                                            </MenuItem>
                                        ))}
                                    </Field>
                                </FormControl>
                            </Grid>
                        )}
                        <Grid xs={12} item>
                            <Field
                                as={TextField}
                                label={i18n.t('quickemessage.dialog.form.message')}
                                name="message"
                                inputRef={messageInputRef}
                                error={touched.message && Boolean(errors.message)}
                                helperText={touched.message && errors.message}
                                variant="outlined"
                                margin="dense"
                                multiline={true}
                                minRows={7}
                                fullWidth
                                disabled={quickemessage.mediaPath || attachment ? true : false}
                            />
                        </Grid>
                        <Grid xs={12} item>
                            <Field
                                as={TextField}
                                label={i18n.t('quickemessage.dialog.form.additionalMessage')}
                                name="additionalMessage"
                                error={touched.additionalMessage && Boolean(errors.additionalMessage)}
                                helperText={touched.additionalMessage && errors.additionalMessage}
                                variant="outlined"
                                margin="dense"
                                multiline={true}
                                minRows={3}
                                fullWidth
                            />
                        </Grid>
                        <Grid item>
                            <MessageVariablesPicker
                                disabled={isSubmitting}
                                type={values.type}
                                onClick={(value) => handleClickMsgVar(value, setFieldValue)}
                            />
                        </Grid>
                        {(profile === 'admin' || profile === 'supervisor') && (
                            <Grid xs={12} item>
                                <FormControl variant="outlined" margin="dense" fullWidth>
                                    <InputLabel id="geral-selection-label">
                                        {i18n.t('quickemessage.dialog.form.geral')}
                                    </InputLabel>
                                    <Field
                                        as={Select}
                                        label={i18n.t('quickemessage.dialog.form.geral')}
                                        placeholder={i18n.t('quickemessage.dialog.form.geral')}
                                        labelId="geral-selection-label"
                                        id="geral"
                                        name="geral"
                                        error={touched.geral && Boolean(errors.geral)}
                                    >
                                        <MenuItem value={true}>Ativo</MenuItem>
                                        <MenuItem value={false}>Inativo</MenuItem>
                                    </Field>
                                </FormControl>
                            </Grid>
                        )}
                        {(quickemessage.mediaPath || attachment) && (
                            <Grid xs={12} item>
                                <Button startIcon={<AttachFileIcon />}>
                                    {attachment ? attachment.name : quickemessage.mediaName}
                                </Button>
                                <IconButton onClick={() => setConfirmationOpen(true)} color="secondary">
                                    <DeleteOutlineIcon color="secondary" />
                                </IconButton>
                            </Grid>
                        )}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    {!attachment && !quickemessage.mediaPath && (
                        <Button
                            color="primary"
                            onClick={() => attachmentFile.current.click()}
                            disabled={isSubmitting || values.type === 'EMAIL_FILTER_COMMENT'}
                            variant="outlined"
                        >
                            {i18n.t('quickemessage.dialog.buttons.attach')}
                        </Button>
                    )}
                    <Button onClick={handleClose} color="secondary" disabled={isSubmitting} variant="outlined">
                        {i18n.t('quickemessage.dialog.buttons.cancel')}
                    </Button>
                    <Button
                        type="submit"
                        color="primary"
                        disabled={isSubmitting}
                        variant="contained"
                        className={classes.btnWrapper}
                    >
                        {quickemessageId
                            ? `${i18n.t('quickemessage.dialog.buttons.edit')}`
                            : `${i18n.t('quickemessage.dialog.buttons.add')}`}
                        {isSubmitting && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </Button>
                </DialogActions>
            </Form>
        );
    };

    return (
        <div className={classes.root}>
            <ConfirmationModal
                title={i18n.t('quickemessage.confirmationModal.deleteTitle')}
                open={confirmationOpen}
                onClose={() => setConfirmationOpen(false)}
                onConfirm={deleteMedia}
            >
                {i18n.t('quickemessage.confirmationModal.deleteMessage')}
            </ConfirmationModal>
            <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth scroll="paper">
                <DialogTitle id="form-dialog-title">
                    {quickemessageId
                        ? `${i18n.t('quickemessage.dialog.edit')}`
                        : `${i18n.t('quickemessage.dialog.add')}`}
                </DialogTitle>
                <div style={{ display: 'none' }}>
                    <input
                        type="file"
                        accept=".png,.jpg,.jpeg"
                        ref={attachmentFile}
                        onChange={(e) => handleAttachmentFile(e)}
                    />
                </div>
                <Formik
                    initialValues={quickemessage}
                    enableReinitialize={true}
                    validationSchema={QuickeMessageSchema}
                    onSubmit={(values, actions) => {
                        setTimeout(() => {
                            handleSaveQuickeMessage(values);
                            actions.setSubmitting(false);
                        }, 400);
                    }}
                >
                    {(props) => <QuickMessageForm {...props} />}
                </Formik>
            </Dialog>
        </div>
    );
};

export default QuickMessageDialog;
