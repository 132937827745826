import { Box, IconButton, InputAdornment, Popover, TextField } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import React, { useEffect, useState } from 'react';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import './dateRange.css';

const DateRangePopover = ({ onDateChange, label, dates }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [range, setRange] = useState([{
        startDate: null,
        endDate: null,
        key: 'selection'
    }]);

    const [visualizationRange, setVisualizationRange] = useState([{
        startDate: null,
        endDate: null,
        key: 'selection'
    }]);

    useEffect(() => {
        if (!range[0]?.startDate || !range[0]?.endDate) {
            dates(null)
        } else {
            dates({ startDate: range[0].startDate, endDate: range[0].endDate });
        }
    }, [range]);

    const theme = useTheme();

    const customStyles = {
        '& .rdrCalendarWrapper, & .rdrDefinedRangesWrapper': {
            backgroundColor: theme.palette.mode === 'dark' ? '#212b36' : '#fff',
            borderRight: `solid 1px ${theme.palette.mode === 'dark' ? '#212b36' : '#fff'}`,
        },
        '& .rdrStaticRange': {
            backgroundColor: theme.palette.mode === 'dark' ? '#212b36' : '#fff',
        },
        '& .rdrStaticRangeLabel': {
            color: theme.palette.mode === 'dark' ? '#fff' : '#000',
            transition: 'color 0.3s ease',
        },
        '& .rdrStaticRange:hover .rdrStaticRangeLabel': {
            color: `${theme.palette.mode === 'dark' ? '#212b36' : '#fff'} !important`,
        },
        '& .rdrStaticRangeSelected .rdrStaticRangeLabel': {
            color: `${theme.palette.primary.main} !important`,
        },
        '& .rdrDateDisplayWrapper': {
            display: 'none'
        },
        '& .rdrMonthAndYearWrapper': {
            color: theme.palette.mode === 'dark' ? '#fff' : '#000',
        },
        '& .rdrDayNumber': {
            color: theme.palette.mode === 'dark' ? '#fff' : '#000',
        },
        '& .rdrNextPrevButton ': {
            backgroundColor: theme.palette.mode === 'dark' ? '#2d3a49' : '#e8e8e8'
        },
        '& .rdrMonthAndYearPickers select': {
            color: theme.palette.mode === 'dark' ? '#fff' : '#000',
            backgroundColor: theme.palette.mode === 'dark' ? '#2d3a49' : '#e8e8e8',
        },
        '& .rdrSelected': {
            backgroundColor: theme.palette.primary.main,
            color: '#fff',
        },
        '& .rdrDay': {
            '& .rdrDayNumber span': {
                color: theme.palette.mode === 'dark' ? '#fff' : '#000',
            },
        },
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'date-range-popover' : undefined;

    useEffect(() => {
        if (anchorEl || open) {
            setTimeout(() => {
                document.querySelectorAll('.rdrWeekDay').forEach((element) => {
                    if (element.textContent) {
                        element.textContent = element.textContent.slice(0, 3);
                    }
                });
            }, 100);
        }
    }, [anchorEl, open]);

    const handleSelect = (ranges) => {
        const { selection } = ranges;
        setRange([selection]);
        setVisualizationRange([selection]);
        onDateChange([selection.startDate, selection.endDate]);
    };

    const handleClearDates = (event) => {
        event.stopPropagation();
        setRange([{ startDate: null, endDate: null, key: 'selection' }]);
        setVisualizationRange([{ startDate: null, endDate: null, key: 'selection' }]);
        onDateChange([null, null]);
    };

    useEffect(() => {
        if (!range[0].startDate && !range[0].endDate) {
            const today = new Date();
            setVisualizationRange([{
                startDate: today,
                endDate: today,
                key: 'selection'
            }]);
        }
    }, [range]);

    return (
        <Box>
            <TextField
                size="small"
                variant='outlined'
                label={label}
                InputLabelProps={{ shrink: true }}
                value={(range[0].startDate || range[0].endDate)
                    ? `${format(range[0].startDate, 'dd/MM/yy')} - ${format(range[0].endDate, 'dd/MM/yy')}`
                    : 'Selecione as datas'}
                onClick={handleClick}
                fullWidth
                InputProps={{
                    readOnly: true,
                    endAdornment: (
                        (range[0].startDate || range[0].endDate) ? (
                            <InputAdornment position="end">
                                <IconButton onClick={handleClearDates} edge="end">
                                    <CloseIcon />
                                </IconButton>
                            </InputAdornment>
                        ) : null
                    ),
                }}
            />


            {open && (
                <Box
                    sx={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backdropFilter: 'blur(3px)',
                        zIndex: 999,
                    }}
                />
            )}

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                PaperProps={{
                    sx: {
                        boxShadow: `0px 4px 20px rgba(0, 0, 0, 0.2)`,
                        borderRadius: '8px',
                    },
                }}
            >
                <Box p={2} sx={customStyles}>
                    <DateRange
                        locale={ptBR}
                        editableDateInputs={true}
                        onChange={handleSelect}
                        months={2}
                        direction="horizontal"
                        ranges={visualizationRange}
                    />
                </Box>
            </Popover>
        </Box>
    );
};

export default DateRangePopover;
