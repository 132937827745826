import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useTheme } from '@material-ui/core';

export default function CircularProgressWithLabel({ value, ...props }) {
    const theme = useTheme();
    return (
        <Box position="relative" display="inline-flex">
            <CircularProgress variant="determinate" value={value} {...props} />
            <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Typography
                    variant="caption"
                    component="div"
                    color="textSecondary"
                    style={{ color: theme.mode === 'dark' ? '#FFF' : 'rgb(241 241 241)' }}
                >
                    {`${Math.round(value)}%`}
                </Typography>
            </Box>
        </Box>
    );
}
