import React, { useEffect, useState } from 'react';
import { Button, Chip, IconButton, Tooltip, LinearProgress } from '@material-ui/core';
import AttachmentIcon from '@material-ui/icons/Attachment';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import toastError from '../../errors/toastError';
import DeleteIcon from '@material-ui/icons/Delete';
import downloadFileToBlob from '../../helpers/downloadFileToBlob';

function generateUUIDv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = (crypto.getRandomValues(new Uint8Array(1))[0] & 0x0f) | (c === 'x' ? 0 : 0x8);
        return r.toString(16);
    });
}

const FileUpload = ({ typeComponent, onChange, styleComponent, file }) => {
    const [selectedFile, setSelectedFile] = useState(null);
    const uuid = generateUUIDv4();

    useEffect(() => {
        (async () => {
            if (!file) return;
            const fileBlob = await downloadFileToBlob(file);
            setSelectedFile(fileBlob);
        })();
    }, [file]);

    const handleFileChange = (event) => {
        const fileData = event.target.files[0];

        const MAX_FILE_SIZE = +process.env.MAX_FILE_SIZE || 150 * 1024 * 1024; // 150MB

        if (fileData && fileData.size > MAX_FILE_SIZE) {
            toastError(
                `O arquivo selecionado é maior que ${+process.env.MAX_FILE_SIZE || 150}MB. Por favor, selecione outro arquivo.`
            );
            return;
        }

        setSelectedFile(fileData);
        if (onChange) {
            onChange(fileData); // Chamando a função onChange com o arquivo selecionado
        }
    };

    const handleDeleteFile = () => {
        setSelectedFile(null);
        onChange(null);
    };

    const handleClickChip = () => {
        // Abrir a janela de seleção de arquivo ao clicar no Chip
        const fileInput = document.getElementById(`file-upload-${uuid}`);
        if (fileInput) {
            fileInput.click();
        }
    };

    const truncateFileName = (fileName) => {
        try {
            const trimmedFileName = fileName.replace(/^\d+_/, '');
            if (trimmedFileName.length > 16) {
                const extension = trimmedFileName.split('.').pop();
                const truncatedName = `(${extension.toUpperCase()}) ${trimmedFileName.substring(0, 13).trim()}...`;
                return truncatedName;
            }
            return trimmedFileName;
        } catch (error) {
            console.error(error);
            return fileName;
        }
    };

    return (
        <div
            style={{
                display: 'flex',
                height: '100%',
                alignItems: 'center',
            }}
        >
            <input
                type="file"
                style={{ display: 'none' }}
                id={`file-upload-${uuid}`}
                onChange={handleFileChange}
                accept=".pdf,.doc,.docx,.txt,.jpeg,.jpg,.png,.bmp,.gif,.svg"
            />
            {selectedFile ? (
                <>
                    {typeComponent === 'queue' ? (
                        <Chip
                            style={styleComponent}
                            icon={<AttachmentIcon />}
                            label={selectedFile?.name ? truncateFileName(selectedFile.name) : 'Arquivo Salvo'}
                            variant="outlined"
                            color="primary"
                            onClick={handleClickChip}
                            onDelete={handleDeleteFile}
                            deleteIcon={<DeleteIcon />}
                        />
                    ) : (
                        <Chip
                            style={styleComponent}
                            label={selectedFile?.name ? truncateFileName(selectedFile.name) : 'Arquivo Salvo'}
                            color="primary"
                            onClick={handleClickChip}
                            onDelete={handleDeleteFile}
                            deleteIcon={<DeleteIcon />}
                        />
                    )}
                </>
            ) : (
                <>
                    {typeComponent === 'queue' ? (
                        <Chip
                            style={styleComponent}
                            icon={<AttachmentIcon />}
                            label={'Escolha o arquivo da fila'}
                            color="primary"
                            onClick={handleClickChip}
                        />
                    ) : (
                        <IconButton size="small" onClick={handleClickChip}>
                            <AttachmentIcon />
                        </IconButton>
                    )}
                </>
            )}
        </div>
    );
};

export default FileUpload;
