import React, { useEffect, useReducer, useState } from 'react';
import { toast } from 'react-toastify';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';

import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';

import MainContainer from '../../components/MainContainer';
import MainHeader from '../../components/MainHeader';
import MainHeaderButtonsWrapper from '../../components/MainHeaderButtonsWrapper';
import Title from '../../components/Title';

import ConfirmationModal from '../../components/ConfirmationModal';
import TableRowSkeleton from '../../components/TableRowSkeleton';
import UserModal from '../../components/UserModal';
import UserStatusIcon from '../../components/UserModal/statusIcon';
import toastError from '../../errors/toastError';
import socket from '../../hooks/useSocket';
import api from '../../services/api';
import { i18n } from '../../translate/i18n';

import { FormControl, InputLabel, Select, Tooltip } from '@material-ui/core';
import Iconify from '../../components/Iconify';
import DeleteUserModal from '../../components/DeleteUserModal';

const reducer = (state, action) => {
    if (action.type === 'LOAD_USERS') {
        const users = action.payload;
        const newUsers = [];

        users.forEach((user) => {
            const userIndex = state.findIndex((u) => u.id === user.id);
            if (userIndex !== -1) {
                state[userIndex] = user;
            } else {
                newUsers.push(user);
            }
        });

        return [...state, ...newUsers];
    }

    if (action.type === 'UPDATE_USERS') {
        const user = action.payload;
        const userIndex = state.findIndex((u) => u.id === user.id);

        if (userIndex !== -1) {
            state[userIndex] = user;
            return [...state];
        } else {
            return [user, ...state];
        }
    }

    if (action.type === 'DELETE_USER') {
        const userId = action.payload;

        const userIndex = state.findIndex((u) => u.id === userId);
        if (userIndex !== -1) {
            state.splice(userIndex, 1);
        }
        return [...state];
    }

    if (action.type === 'RESET') {
        return [];
    }
};

const useStyles = makeStyles((theme) => ({
    mainPaper: {
        flex: 1,
        padding: theme.spacing(1),
        overflowY: 'scroll',
        ...theme.scrollbarStyles,
    },
}));

const Users = () => {
    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [hasMore, setHasMore] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [deletingUser, setDeletingUser] = useState(null);
    const [userModalOpen, setUserModalOpen] = useState(false);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [searchParam, setSearchParam] = useState('');
    const [users, dispatch] = useReducer(reducer, []);
    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
        dispatch({ type: 'RESET' });
        setPageNumber(1);
    }, [searchParam]);

    useEffect(() => {
        setLoading(true);
        const delayDebounceFn = setTimeout(() => {
            const fetchUsers = async () => {
                try {
                    const { data } = await api.get('/users/', {
                        params: { searchParam, pageNumber },
                    });
                    dispatch({ type: 'LOAD_USERS', payload: data.users });
                    setHasMore(data.hasMore);
                    setLoading(false);
                } catch (err) {
                    toastError(err);
                }
            };
            fetchUsers();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [searchParam, pageNumber]);

    useEffect(() => {
        const companyId = localStorage.getItem('companyId');

        socket.on(`company-${companyId}-user`, (data) => {
            if (data.action === 'update' || data.action === 'create') {
                dispatch({ type: 'UPDATE_USERS', payload: data.user });
            }

            if (data.action === 'delete') {
                dispatch({ type: 'DELETE_USER', payload: +data.userId });
            }
        });

        return () => {};
    }, []);

    const handleOpenUserModal = () => {
        setSelectedUser(null);
        setUserModalOpen(true);
    };

    const handleCloseUserModal = () => {
        setSelectedUser(null);
        setUserModalOpen(false);
    };

    const handleSearch = (event) => {
        setSearchParam(event.target.value.toLowerCase());
    };

    const handleEditUser = (user) => {
        setSelectedUser(user);
        setUserModalOpen(true);
    };

    const handleDeleteUser = async (userId) => {
        try {
            await api.delete(`/users/${userId}`);
            toast.success(i18n.t('users.toasts.deleted'));
            setDeletingUser(null);
            setSearchParam('');
            setPageNumber(1);
        } catch (err) {
            toastError(err);
        } finally {
            setConfirmModalOpen(false);
        }
    };

    const loadMore = () => {
        setPageNumber((prevState) => prevState + 1);
    };

    const handleScroll = (e) => {
        if (!hasMore || loading) return;
        const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
        if (scrollHeight - (scrollTop + 100) < clientHeight) {
            loadMore();
        }
    };

    const handleDisabledUser = async (userId, isDisabled) => {
        try {
            await api.put(`/users/disabled/${userId}`, { disabled: isDisabled });
        } catch (error) {
            console.error(error);
        }
    };

    const filteredUsers = (users || []).filter((u) => {
        const isDisabled = disabled === 'true' || disabled === true;
        return u.disabled === isDisabled;
    });

    return (
        <MainContainer>
            {/* 
           <ConfirmationModal
                title={deletingUser && `${i18n.t('users.confirmationModal.deleteTitle')} ${deletingUser.name}?`}
                open={confirmModalOpen}
                onClose={setConfirmModalOpen}
                onConfirm={() => handleDeleteUser(deletingUser.id)}
            >
                {i18n.t('users.confirmationModal.deleteMessage')}
            </ConfirmationModal>
            */}
            <DeleteUserModal
                open={confirmModalOpen}
                onClose={setConfirmModalOpen}
                onConfirm={() => handleDeleteUser(deletingUser?.id || null)}
                userId={deletingUser?.id || null}
            />
            <UserModal
                open={userModalOpen}
                onClose={handleCloseUserModal}
                aria-labelledby="form-dialog-title"
                userId={selectedUser && selectedUser.id}
            />
            <MainHeader>
                <Title>
                    {i18n.t('users.title')} ({users.length})
                </Title>
                <MainHeaderButtonsWrapper>
                    <TextField
                        size="small"
                        variant="outlined"
                        placeholder={i18n.t('contacts.searchPlaceholder')}
                        type="search"
                        value={searchParam}
                        onChange={handleSearch}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon style={{ color: 'gray' }} />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <FormControl variant="outlined" size="small">
                        <InputLabel htmlFor="outlined-filter-native-simple">Filtrar por</InputLabel>
                        <Select
                            native
                            value={disabled}
                            onChange={(e) => setDisabled(e.target.value)}
                            label="Filtrar por"
                            inputProps={{
                                name: 'Filtrar por',
                                id: 'outlined-filter-native-simple',
                            }}
                        >
                            <option value={false}>Ativos</option>
                            <option value={true}>Inativos</option>
                        </Select>
                    </FormControl>
                    <Button variant="contained" color="primary" onClick={handleOpenUserModal}>
                        {i18n.t('users.buttons.add')}
                    </Button>
                </MainHeaderButtonsWrapper>
            </MainHeader>
            <Paper className={classes.mainPaper} variant="outlined" onScroll={handleScroll}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">{i18n.t('users.table.id')}</TableCell>
                            <TableCell align="center">{i18n.t('users.table.status')}</TableCell>
                            <TableCell align="center">{i18n.t('users.table.name')}</TableCell>
                            <TableCell align="center">{i18n.t('users.table.email')}</TableCell>
                            <TableCell align="center">{i18n.t('users.table.profile')}</TableCell>
                            <TableCell align="center">{i18n.t('users.table.whatsapp')}</TableCell>
                            <TableCell align="center">{i18n.t('users.table.startWork')}</TableCell>
                            <TableCell align="center">{i18n.t('users.table.endWork')}</TableCell>
                            <TableCell align="center">{i18n.t('users.table.actions')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <>
                            {filteredUsers.map((user) => (
                                <TableRow key={user.id}>
                                    <TableCell align="center">{user.id}</TableCell>
                                    <TableCell align="center">
                                        <UserStatusIcon user={user} />
                                    </TableCell>
                                    <TableCell align="center">{user.name}</TableCell>
                                    <TableCell align="center">{user.email}</TableCell>
                                    <TableCell align="center">{user.profile}</TableCell>
                                    <TableCell align="center">{user.whatsapp?.name}</TableCell>
                                    <TableCell align="center">{user.startWork}</TableCell>
                                    <TableCell align="center">{user.endWork}</TableCell>
                                    <TableCell align="center">
                                        <Box display="flex" justifyContent="center" alignItems="center">
                                            <IconButton size="small" onClick={() => handleEditUser(user)}>
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton
                                                size="small"
                                                onClick={(e) => {
                                                    setConfirmModalOpen(true);
                                                    setDeletingUser(user);
                                                }}
                                            >
                                                <DeleteOutlineIcon />
                                            </IconButton>
                                            <Tooltip
                                                title={user.disabled ? 'Clique para ativar' : 'Clique para desativar'}
                                                placement="left"
                                            >
                                                <IconButton
                                                    size="small"
                                                    onClick={() => handleDisabledUser(user.id, !user.disabled)}
                                                >
                                                    {user.disabled ? (
                                                        <Iconify
                                                            icon={'gg:block'}
                                                            sx={{ width: '27px', height: '27px', color: '#e53935' }}
                                                        />
                                                    ) : (
                                                        <Iconify
                                                            icon={'gg:unblock'}
                                                            sx={{ width: '23px', height: '23px', color: '#43a047' }}
                                                        />
                                                    )}
                                                </IconButton>
                                            </Tooltip>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            ))}
                            {loading && <TableRowSkeleton columns={9} />}
                        </>
                    </TableBody>
                </Table>
            </Paper>
        </MainContainer>
    );
};

export default Users;
