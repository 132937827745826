import React, { useContext, useEffect, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Grid,
    InputAdornment,
    IconButton,
    MenuItem,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import { Colorize } from '@material-ui/icons';
import { ColorBox } from 'material-ui-color';

import { i18n } from '../../translate/i18n';
import { AuthContext } from '../../context/Auth/AuthContext';
import toastError from '../../errors/toastError';
import api from '../../services/api';

const useStyles = makeStyles((theme) => ({
    btnWrapper: {
        position: 'relative',
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    colorAdorment: {
        width: 20,
        height: 20,
    },
}));

const TagSchema = Yup.object().shape({
    name: Yup.string().min(3, 'Mensagem muito curta').required('Obrigatório'),
    type: Yup.string().required('Obrigatório'),
});

const TagModal = ({ open, onClose, tagId, reload, kanban }) => {
    const classes = useStyles();
    const { user } = useContext(AuthContext);
    const [colorPickerModalOpen, setColorPickerModalOpen] = useState(false);

    const [initialValues, setInitialValues] = useState({
        name: '',
        color: '',
        type: 'tickets',
        kanban: kanban,
    });

    useEffect(() => {
        const fetchData = async () => {
            if (!tagId) return;

            try {
                const { data } = await api.get(`/tags/${tagId}`);
                setInitialValues(data);
            } catch (err) {
                toastError(err);
            }
        };

        if (open) fetchData();
    }, [tagId, open]);

    const handleClose = () => {
        setColorPickerModalOpen(false);
        onClose();
    };

    const handleSaveTag = async (values) => {
        const tagData = { ...values, userId: user.id, kanban: kanban };

        try {
            if (tagId) {
                await api.put(`/tags/${tagId}`, tagData);
            } else {
                await api.post('/tags', tagData);
            }
            toast.success(kanban === 0 ? `${i18n.t('tagModal.success')}` : `${i18n.t('tagModal.successKanban')}`);
            if (typeof reload === 'function') {
                reload();
            }
        } catch (err) {
            toastError(err);
        }
        handleClose();
    };

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth scroll="paper">
            <DialogTitle>
                {tagId
                    ? kanban === 0
                        ? `${i18n.t('tagModal.title.edit')}`
                        : `${i18n.t('tagModal.title.editKanban')}`
                    : kanban === 0
                        ? `${i18n.t('tagModal.title.add')}`
                        : `${i18n.t('tagModal.title.addKanban')}`}
            </DialogTitle>
            <Formik
                initialValues={initialValues}
                enableReinitialize
                validationSchema={TagSchema}
                onSubmit={(values, actions) => {
                    setTimeout(() => {
                        handleSaveTag(values);
                        actions.setSubmitting(false);
                    }, 400);
                }}
            >
                {({ touched, errors, isSubmitting, values, setFieldValue }) => (
                    <Form>
                        <DialogContent dividers>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Field
                                        as={TextField}
                                        label={i18n.t('tagModal.form.name')}
                                        name="name"
                                        error={touched.name && Boolean(errors.name)}
                                        helperText={touched.name && errors.name}
                                        variant="outlined"
                                        margin="dense"
                                        fullWidth
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <Field
                                        disabled={!!tagId}
                                        as={TextField}
                                        select
                                        label={i18n.t('tagModal.form.type')}
                                        name="type"
                                        variant="outlined"
                                        margin="dense"
                                        fullWidth
                                        error={touched.type && Boolean(errors.type)}
                                        helperText={touched.type && errors.type}
                                    >
                                        <MenuItem value="tickets">{i18n.t('tagModal.type.tickets')}</MenuItem>
                                        <MenuItem value="contacts">{i18n.t('tagModal.type.contacts')}</MenuItem>
                                    </Field>
                                </Grid>

                                <Grid item xs={12}>
                                    <Field
                                        as={TextField}
                                        label={i18n.t('tagModal.form.color')}
                                        name="color"
                                        variant="outlined"
                                        margin="dense"
                                        fullWidth
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <div
                                                        style={{ backgroundColor: values.color }}
                                                        className={classes.colorAdorment}
                                                    ></div>
                                                </InputAdornment>
                                            ),
                                            endAdornment: (
                                                <IconButton
                                                    size="small"
                                                    onClick={() => setColorPickerModalOpen(!colorPickerModalOpen)}
                                                >
                                                    <Colorize />
                                                </IconButton>
                                            ),
                                        }}
                                    />
                                </Grid>

                                {colorPickerModalOpen && (
                                    <Grid item xs={12}>
                                        <ColorBox
                                            disableAlpha
                                            hslGradient={false}
                                            value={values.color}
                                            onChange={(val) => {
                                                setFieldValue('color', `#${val.hex}`);
                                            }}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        </DialogContent>

                        <DialogActions>
                            <Button onClick={handleClose} color="secondary" variant="outlined" disabled={isSubmitting}>
                                {i18n.t('tagModal.buttons.cancel')}
                            </Button>
                            <Button
                                type="submit"
                                color="primary"
                                variant="contained"
                                className={classes.btnWrapper}
                                disabled={isSubmitting}
                            >
                                {tagId
                                    ? `${i18n.t('tagModal.buttons.okEdit')}`
                                    : `${i18n.t('tagModal.buttons.okAdd')}`}
                                {isSubmitting && <CircularProgress size={24} className={classes.buttonProgress} />}
                            </Button>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
};

export default TagModal;
