import { Chip, FormControl, Paper, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useEffect, useRef, useState } from 'react';
import toastError from '../../errors/toastError';
import api from '../../services/api';

export function TagsContactContainer({ setSelecteds, selecteds = [] }) {
    const [tags, setTags] = useState([]);
    const isMounted = useRef(true);

    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    useEffect(() => {
        loadTags();
    }, []); 

    const loadTags = async () => {
        try {
            const { data } = await api.get('/tags/list', { params: { type: 'contacts' } });
            if (isMounted.current) {
                setTags(data);
                if (selecteds?.length > 0) {
                    const filteredSelecteds = data.filter((tag) => selecteds.some((s) => s.id === tag.id));
                    setSelecteds(filteredSelecteds);
                }
            }
        } catch (err) {
            toastError(err);
        }
    };

    const handleChange = (value) => {
        setSelecteds(value || []);
    };

    return (
        <FormControl size="small" variant="outlined" fullWidth>
            <Autocomplete
                multiple
                size="small"
                options={tags}
                value={selecteds}
                freeSolo
                onChange={(event, value) => handleChange(value)}
                getOptionLabel={(option) => option.name || ''}
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                        <Chip
                            key={option.id}
                            variant="outlined"
                            style={{
                                backgroundColor: option.color || '#eee',
                                color: '#FFF',
                                marginRight: 1,
                                padding: 1,
                                fontWeight: 'bold',
                                paddingLeft: 5,
                                paddingRight: 5,
                                borderRadius: '5px',
                                fontSize: '0.8em',
                                whiteSpace: 'nowrap',
                            }}
                            label={option.name}
                            {...getTagProps({ index })}
                            size="small"
                        />
                    ))
                }
                renderInput={(params) => (
                    <TextField
                        {...params}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ ...params.InputProps }}
                        variant="outlined"
                        label="Tags"
                        placeholder="Tags"
                    />
                )}
                PaperComponent={({ children }) => (
                    <Paper style={{ width: 400, marginLeft: 6 }}>{children}</Paper>
                )}
            />
        </FormControl>
    );
}
