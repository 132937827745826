import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Badge from '@material-ui/core/Badge';
import InputBase from '@material-ui/core/InputBase';
import Paper from '@material-ui/core/Paper';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import ChatIcon from '@material-ui/icons/Chat';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import NewTicketModal from '../NewTicketModal';
import TabPanel from '../TabPanel';
import TicketsList from '../TicketsListCustom';

import { Button } from '@material-ui/core';
import { AuthContext } from '../../context/Auth/AuthContext';
import { i18n } from '../../translate/i18n';
import { Can } from '../Can';
import { TagsFilter } from '../TagsFilter';
import TicketsQueueSelect from '../TicketsQueueSelect';
import { UsersFilter } from '../UsersFilter';

const useStyles = makeStyles((theme) => ({
    ticketsWrapper: {
        position: 'relative',
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        overflow: 'hidden',
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
    },

    tabsHeader: {
        flex: 'none',
        backgroundColor: theme.palette.background.default,
    },

    settingsIcon: {
        alignSelf: 'center',
        marginLeft: 'auto',
        padding: 8,
    },

    tab: {
        minWidth: 120,
        width: 120,
    },

    ticketOptionsBox: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(1),
    },

    serachInputWrapper: {
        flex: 1,
        backgroundColor: theme.palette.background.default,
        display: 'flex',
        borderRadius: 40,
        padding: 4,
        marginRight: theme.spacing(1),
    },

    searchIcon: {
        color: theme.palette.primary.main,
        marginLeft: 6,
        marginRight: 6,
        alignSelf: 'center',
    },

    searchInput: {
        flex: 1,
        border: 'none',
        borderRadius: 25,
        outline: 'none',
    },

    badge: {
        right: '-10px',
    },
    show: {
        display: 'block',
    },
    hide: {
        display: 'none !important',
    },
}));

const TicketsManagerTabs = () => {
    const classes = useStyles();
    const history = useHistory();

    const [searchParam, setSearchParam] = useState('');
    const [tab, setTab] = useState('open');
    const [tabOpen, setTabOpen] = useState('open');
    const [newTicketModalOpen, setNewTicketModalOpen] = useState(false);
    const [showAllTickets, setShowAllTickets] = useState(false);
    const searchInputRef = useRef();
    const { user } = useContext(AuthContext);
    const { profile } = user;

    const [openCount, setOpenCount] = useState(0);
    const [pendingCount, setPendingCount] = useState(0);


    const [selectedTags, setSelectedTags] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);

    const renderQueues = () => {
        const queues = [{
            id: 0,
            name: "Sem Fila",
            color: "#808080",
            UserQueue: {
                userId: user.id,
                queueId: 0,
            }
        }];

        if (user?.queues) {
            queues.push(...user.queues);
        }

        return queues;
    }

    const userQueueIds = renderQueues().map((q) => q.id);
    const [selectedQueueIds, setSelectedQueueIds] = useState(userQueueIds || []);


    useEffect(() => {
        if (user.profile.toUpperCase() === 'ADMIN' || user.profile.toUpperCase() === 'SUPERVISOR') {
            setShowAllTickets(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (tab === 'search') {
            searchInputRef.current.focus();
        }
    }, [tab]);

    let searchTimeout;

    const handleSearch = (e) => {
        const searchedTerm = e.target.value.toLowerCase();

        clearTimeout(searchTimeout);

        if (searchedTerm === '') {
            setSearchParam(searchedTerm);
            setTab('open');
            return;
        }

        searchTimeout = setTimeout(() => {
            setSearchParam(searchedTerm);
        }, 500);
    };

    const handleChangeTab = (e, newValue) => {
        setTab(newValue);
    };

    const handleChangeTabOpen = (e, newValue) => {
        setTabOpen(newValue);
    };

    const applyPanelStyle = (status) => {
        if (tabOpen !== status) {
            return { width: 0, height: 0 };
        }
    };

    const handleCloseOrOpenTicket = (ticket) => {
        setNewTicketModalOpen(false);
        if (ticket !== undefined && ticket.uuid !== undefined) {
            history.push(`/tickets/${ticket.uuid}`);
        }
    };

    const handleSelectedTags = (selecteds) => {
        const tags = selecteds.map((t) => t.id);
        setSelectedTags(tags);
    };

    const handleSelectedUsers = (selecteds) => {
        const users = selecteds.map((t) => t.id);
        setSelectedUsers(users);
    };

    return (
        <Paper elevation={0} variant="outlined" className={classes.ticketsWrapper}>
            <NewTicketModal
                modalOpen={newTicketModalOpen}
                onClose={(ticket) => {
                    console.log('ticket', ticket);
                    handleCloseOrOpenTicket(ticket);
                }}
            />

            {(profile === 'admin' || profile === 'supervisor') && (
                <>
                    <Paper elevation={0} square className={classes.tabsHeader}>
                        <Tabs
                            value={tab}
                            onChange={handleChangeTab}
                            variant="fullWidth"
                            indicatorColor="primary"
                            textColor="primary"
                            aria-label="icon label tabs example"
                        >
                            <Tab
                                value={'open'}
                                icon={<ChatIcon />}
                                label={i18n.t('tickets.tabs.open.title')}
                                classes={{ root: classes.tab }}
                            />
                            <Tab
                                value={'closed'}
                                icon={<DoneAllIcon />}
                                label={i18n.t('tickets.tabs.closed.title')}
                                classes={{ root: classes.tab }}
                            />
                            <Tab
                                value={'search'}
                                icon={<SearchIcon />}
                                label={i18n.t('tickets.tabs.search.title')}
                                classes={{ root: classes.tab }}
                            />
                        </Tabs>
                    </Paper>
                </>
            )}
            {profile === 'user' && (
                <>
                    <Paper elevation={0} square className={classes.tabsHeader}>
                        <Tabs
                            value={tab}
                            onChange={handleChangeTab}
                            variant="fullWidth"
                            indicatorColor="primary"
                            textColor="primary"
                            aria-label="icon label tabs example"
                        >
                            <Tab
                                value={'open'}
                                icon={<ChatIcon />}
                                label={i18n.t('tickets.tabs.open.title')}
                                classes={{ root: classes.tab }}
                            />
                        </Tabs>
                    </Paper>
                </>
            )}
            <Paper square elevation={0} className={classes.ticketOptionsBox}>
                {tab === 'search' ? (
                    <div className={classes.serachInputWrapper}>
                        <SearchIcon className={classes.searchIcon} />
                        <InputBase
                            className={classes.searchInput}
                            inputRef={searchInputRef}
                            placeholder={i18n.t('tickets.search.placeholder')}
                            type="search"
                            onChange={handleSearch}
                        />
                    </div>
                ) : (
                    <>
                        <Button variant="outlined" color="primary" onClick={() => setNewTicketModalOpen(true)}>
                            {i18n.t('ticketsManager.buttons.newTicket')}
                        </Button>
                        <Can
                            role={user.profile}
                            perform="tickets-manager:showall"
                            yes={() => (
                                <FormControlLabel
                                    label={i18n.t('tickets.buttons.showAll')}
                                    labelPlacement="start"
                                    control={
                                        <Switch
                                            size="small"
                                            checked={showAllTickets}
                                            onChange={() => setShowAllTickets((prevState) => !prevState)}
                                            name="showAllTickets"
                                            color="primary"
                                        />
                                    }
                                />
                            )}
                        />
                    </>
                )}
                <TicketsQueueSelect
                    style={{ marginLeft: 6 }}
                    selectedQueueIds={selectedQueueIds}
                    userQueues={renderQueues()}
                    onChange={(values) => setSelectedQueueIds(values)}
                />
            </Paper>
            <TabPanel value={tab} name="open" className={classes.ticketsWrapper}>
                <Tabs
                    value={tabOpen}
                    onChange={handleChangeTabOpen}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                >
                    <Tab
                        label={
                            <Badge className={classes.badge} badgeContent={openCount} color="primary" max={1000}>
                                {i18n.t('ticketsList.assignedHeader')}
                            </Badge>
                        }
                        value={'open'}
                    />
                    <Tab
                        label={
                            <Badge className={classes.badge} badgeContent={pendingCount} color="primary" max={1000}>
                                {i18n.t('ticketsList.pendingHeader')}
                            </Badge>
                        }
                        value={'pending'}
                    />
                </Tabs>
                <Paper className={classes.ticketsWrapper}>
                    <TicketsList
                        status="open"
                        showAll={showAllTickets}
                        selectedQueueIds={selectedQueueIds}
                        updateCount={(val) => setOpenCount(val)}
                        handleChangeTab={() => { }}
                        style={applyPanelStyle('open')}
                    />
                    <TicketsList
                        status="pending"
                        selectedQueueIds={selectedQueueIds}
                        updateCount={(val) => setPendingCount(val)}
                        handleChangeTab={(status) => {
                            setTab('open');
                            setTabOpen('open');
                        }}
                        style={applyPanelStyle('pending')}
                    />
                </Paper>
            </TabPanel>
            <TabPanel value={tab} name="closed" className={classes.ticketsWrapper}>
                <TicketsList
                    status="closed"
                    showAll={true}
                    handleChangeTab={() => { }}
                    selectedQueueIds={selectedQueueIds}
                />
            </TabPanel>
            <TabPanel value={tab} name="search" className={classes.ticketsWrapper}>
                <TagsFilter onFiltered={handleSelectedTags} />
                {(profile === 'admin' || profile === 'supervisor') && <UsersFilter onFiltered={handleSelectedUsers} />}
                <TicketsList
                    searchParam={searchParam}
                    showAll={true}
                    handleChangeTab={() => { }}
                    tags={selectedTags}
                    users={selectedUsers}
                    selectedQueueIds={selectedQueueIds}
                />
            </TabPanel>
        </Paper>
    );
};

export default TicketsManagerTabs;
