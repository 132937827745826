import { Box, Divider } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useEffect, useState } from 'react';

import api from '../../services/api';
import { i18n } from '../../translate/i18n';
import generatePassword from '../../utils/generatePassword';
import OtpInput from '../OtpInput';

function filterById(arr, idToExclude) {
    return arr.filter((item) => item.id !== idToExclude);
}

const DeleteUserModal = ({ open, onClose, onConfirm, userId }) => {
    const [password, setPassword] = useState(generatePassword().toUpperCase());
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [ticketsCount, setTicketsCount] = useState(0);
    const [users, setUsers] = useState([]);
    const [loadingUsers, setLoadingUsers] = useState(false);
    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
    const [otpValue, setOtpValue] = useState(Array(8).fill(''));

    const handleOtpChange = (value) => {
        setOtpValue(value);
    };

    useEffect(() => {
        if (open && userId) {
            setPassword(generatePassword().toUpperCase());
            setLoadingUsers(true);
            (async () => {
                try {
                    const { data } = await api.get(`/users/list`);
                    const filteredUsers = filterById(data, userId);
                    setUsers(filteredUsers);
                } catch (error) {
                    console.error('Failed to load users:', error);
                } finally {
                    setLoadingUsers(false);
                }
            })();
        }
    }, [open, userId]);

    useEffect(() => {
        if (open && userId) {
            setLoadingUsers(true);
            (async () => {
                try {
                    const { data } = await api.get(`/users/count-tickets/${userId}`);
                    setTicketsCount(data.count);
                } catch (error) {
                    console.error('Failed to load users:', error);
                } finally {
                    setLoadingUsers(false);
                }
            })();
        }
    }, [open, userId]);

    const handleChangeUser = async () => {
        setIsSubmitting(true);
        try {
            await api.put(`/ticket/change-user/${userId}`, { userId: selectedUser.id });
            await onConfirm();
        } catch (error) {
            console.error('Failed to load users:', error);
        } finally {
            setIsSubmitting(false);
            setTimeout(() => {
                onClose(false);
            }, 500);
        }
    };

    const handleDeleteUser = async () => {
        setIsSubmitting(true);
        try {
            await onConfirm();
        } catch (error) {
            console.error('Failed to delete ticket:', error);
        } finally {
            setTimeout(() => {
                setIsSubmitting(false);
                onClose(false);
            }, 500);
        }
    };

    function comparePasswordWithOtp(password, otpValue) {
        if (!password || !otpValue) return false;

        const cleanedOtpValue = otpValue.join('');

        if (password === cleanedOtpValue) {
            console.log('Senha correta!');
            return true;
        } else {
            console.log('Senha incorreta!');
            return false;
        }
    }

    const isMatch = comparePasswordWithOtp(password, otpValue);

    return (
        <Dialog open={open} onClose={() => onClose(false)} aria-labelledby="not-user-dialog">
            <DialogTitle id="not-user-dialog">{i18n.t('deleteUserModal.title')}</DialogTitle>
            <DialogContent dividers>
                <Typography variant="body1" style={{ marginBottom: '25px' }}>
                    {i18n.t(`deleteUserModal.${ticketsCount == 0 ? 'deleteMessage' : 'message'}`)}
                </Typography>
                {ticketsCount > 0 && (
                    <>
                        <Typography variant="button">
                            {i18n.t('deleteUserModal.count')}
                            {ticketsCount}
                        </Typography>

                        <Autocomplete
                            size="small"
                            options={users}
                            getOptionLabel={(option) => option.name}
                            value={selectedUser}
                            onChange={(event, newValue) => {
                                setSelectedUser(newValue);
                            }}
                            loading={loadingUsers}
                            //  getOptionDisabled={(option) => option.status !== 'CONNECTED'}
                            renderOption={(option) => (
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    //  style={{ opacity: option.status === 'CONNECTED' ? 1 : 0.5 }}
                                >
                                    <Box
                                        style={{
                                            width: 10,
                                            height: 10,
                                            borderRadius: '50%',
                                            backgroundColor: option.online ? '#00cc00' : '#ff0000',
                                        }}
                                    />
                                    <Box ml={1}>{option.name}</Box>
                                </Box>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={i18n.t('deleteUserModal.selectUser')}
                                    variant="outlined"
                                    margin="normal"
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <>
                                                {loadingUsers ? <CircularProgress color="inherit" size={20} /> : null}
                                                {params.InputProps.endAdornment}
                                            </>
                                        ),
                                    }}
                                />
                            )}
                        />

                        <Box>
                            <Divider style={{ marginBottom: '16px' }} />
                            <Typography
                                variant="body2"
                                color="textSecondary"
                                style={{ textAlign: 'center', marginBottom: '8px' }}
                            >
                                Digite o seguinte código para liberar a exclusão do usuário:
                            </Typography>
                            <Typography
                                variant="h6"
                                color="textSecondary"
                                style={{ textAlign: 'center', marginBottom: '16px' }}
                            >
                                <strong>{`${password?.slice(0, 4)} - ${password?.slice(4, 8)}`}</strong>
                            </Typography>
                            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '16px' }}>
                                <OtpInput length={8} otp={otpValue} isSplit setOtp={handleOtpChange} />
                            </div>
                        </Box>
                    </>
                )}
            </DialogContent>
            <DialogActions style={{ justifyContent: 'space-between' }}>
                <Button variant="contained" onClick={() => onClose(false)} color="default" disabled={isSubmitting}>
                    {i18n.t('deleteUserModal.buttons.cancel')}
                </Button>

                {ticketsCount == 0 ? (
                    <>
                        <Button
                            variant="contained"
                            disabled={isSubmitting}
                            onClick={async () => {
                                setIsSubmitting(true);
                                await onConfirm();
                                setIsSubmitting(false);
                            }}
                            color="secondary"
                        >
                            {i18n.t('deleteUserModal.buttons.delete')}
                            {isSubmitting && <CircularProgress size={24} />}
                        </Button>
                    </>
                ) : (
                    <>
                        {(!selectedUser || ticketsCount == 0) && (
                            <Button
                                variant="contained"
                                onClick={() => {
                                    handleDeleteUser();
                                }}
                                disabled={isSubmitting || !isMatch}
                                color="secondary"
                            >
                                {i18n.t('deleteUserModal.buttons.deleteUser')}
                                {isSubmitting && <CircularProgress size={24} />}
                            </Button>
                        )}
                        {selectedUser && (
                            <Button
                                variant="contained"
                                onClick={() => handleChangeUser()}
                                disabled={isSubmitting || !selectedUser || !isMatch}
                                color="primary"
                            >
                                {i18n.t('deleteUserModal.buttons.changeUser')}
                                {isSubmitting && <CircularProgress size={24} />}
                            </Button>
                        )}
                    </>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default DeleteUserModal;
