import React from "react";
import { TextField, Box } from "@material-ui/core";

const OtpInput = ({ length = 8, otp, setOtp, isSplit = false }) => {

    // Função para lidar com mudanças nos inputs individuais
    const handleChange = (e, index) => {
        const { value } = e.target;
        if (/^[a-zA-Z0-9]$/.test(value) || value === "") {
            const newOtp = [...otp];
            newOtp[index] = `${value}`.toUpperCase();
            setOtp(newOtp);

            if (value && index < length - 1) {
                document.getElementById(`otp-${index + 1}`).focus();
            }
        }
    };

    // Função para lidar com o evento de apagar/backspace
    const handleBackspace = (e, index) => {
        if (e.key === "Backspace" && otp[index] === "" && index > 0) {
            document.getElementById(`otp-${index - 1}`).focus();
        }
    };

    // Função para lidar com o evento de colar texto
    const handlePaste = (e) => {
        e.preventDefault();
        const pastedData = e.clipboardData.getData("Text");

        // Remove caracteres que não sejam letras ou números
        const alphanumericData = pastedData.replace(/[^a-zA-Z0-9]/g, "").slice(0, length);

        // Preenche os inputs do OTP com os caracteres alfanuméricos
        const newOtp = Array(length).fill("");
        for (let i = 0; i < alphanumericData.length; i++) {
            newOtp[i] = alphanumericData[i].toUpperCase();
        }

        setOtp(newOtp);
    };

    // Calcular o ponto de divisão
    const splitIndex = Math.ceil(length / 2);

    return (
        <Box
            id="otp-input"
            display="flex"
            justifyContent="center"
            alignItems="center"
            onPaste={handlePaste} // Adiciona manipulador de paste
        >
            {otp.map((_, index) => (
                <React.Fragment key={index}>
                    <TextField
                        id={`otp-${index}`}
                        value={otp[index]}
                        onChange={(e) => handleChange(e, index)}
                        onKeyDown={(e) => handleBackspace(e, index)}
                        size="small"
                        autoComplete="off"
                        inputProps={{
                            maxLength: 1,
                            style: { textAlign: "center" },
                        }}
                        variant="outlined"
                        style={{
                            width: "45px",  // Largura ajustada para criar um quadrado
                            height: "35px", // Altura ajustada para criar um quadrado
                            textAlign: "center",
                            marginRight: "5px", // Espaço entre os campos
                        }}
                    />
                    {isSplit && index === splitIndex - 1 && (
                        <Box style={{ fontSize: '25px', margin: '10px 10px 0px 5px' }} component="span" mx={1}>-</Box> // Adiciona traço na divisão
                    )}
                </React.Fragment>
            ))}
        </Box>
    );
};

export default OtpInput;
