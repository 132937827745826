import { IconButton, InputAdornment } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import React from 'react';
import { IMaskInput } from 'react-imask';
import Iconify from '../Iconify';

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
        <IMaskInput
            {...other}
            mask={[
                {
                    mask: '000.000.000-00',
                    maxLength: 11,
                },
                {
                    mask: '00.000.000/0000-00',
                    maxLength: 18,
                },
            ]}
            lazy={false}
            definitions={{
                '#': /[1-9]/,
            }}
            inputRef={ref}
            onAccept={(value) => onChange({ target: { name: props.name, value } })}
            overwrite
        />
    );
});

const TextCEPMaskCustom = React.forwardRef(function TextCEPMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
        <IMaskInput
            {...other}
            mask={[
                {
                    mask: '00000-000',
                    maxLength: 9,
                },
            ]}
            lazy={false}
            definitions={{
                '#': /[1-9]/,
            }}
            inputRef={ref}
            onAccept={(value) => onChange({ target: { name: props.name, value } })}
            overwrite
        />
    );
});

const TextRgMaskCustom = React.forwardRef(function TextRgMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
        <IMaskInput
            {...other}
            mask={[
                {
                    mask: '00.000.000-0',
                    maxLength: 12,
                },
            ]}
            lazy={false}
            definitions={{
                '#': /[1-9]/,
            }}
            inputRef={ref}
            onAccept={(value) => onChange({ target: { name: props.name, value } })}
            overwrite
        />
    );
});

const CpfCnpjMask = ({ onCopy, ...props }) => {
    console.info(props)
    return (
        <TextField
            {...props}
            InputProps={{
                inputComponent: TextMaskCustom,
                ...(props?.value?.length && {
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="copy text"
                                edge="end"
                                onClick={() => onCopy()}
                                style={{ padding: '8px' }}
                            >
                                <Iconify style={{ width: '15px', height: '15px' }} icon={'material-symbols:copy-all'} />
                            </IconButton>
                        </InputAdornment>
                    ),
                })
            }}
        />
    );
};

const CEPMask = ({ onCopy, ...props }) => {
    return (
        <TextField
            {...props}
            InputProps={{
                inputComponent: TextCEPMaskCustom,
                ...(props?.value?.length && {
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="copy text"
                                edge="end"
                                onClick={() => onCopy()}
                                style={{ padding: '8px' }}
                            >
                                <Iconify style={{ width: '15px', height: '15px' }} icon={'material-symbols:copy-all'} />
                            </IconButton>
                        </InputAdornment>
                    ),
                })
            }}
        />
    );
};

const RgMask = ({ onCopy, ...props }) => {
    return (
        <TextField
            {...props}
            InputProps={{
                inputComponent: TextRgMaskCustom,
                ...(props?.value?.length && {
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="copy text"
                                edge="end"
                                onClick={() => onCopy()}
                                style={{ padding: '8px' }}
                            >
                                <Iconify style={{ width: '15px', height: '15px' }} icon={'material-symbols:copy-all'} />
                            </IconButton>
                        </InputAdornment>
                    ),
                })
            }}
        />
    );
};

export { CEPMask, CpfCnpjMask, RgMask };

