import React from 'react';
import isString from 'lodash/isString';
import PropTypes from 'prop-types';
// @mui
import { Box, Typography, Link } from '@material-ui/core';
//

// ----------------------------------------------------------------------

HeaderBreadcrumbs.propTypes = {
    links: PropTypes.array,
    action: PropTypes.node,
    heading: PropTypes.string.isRequired,
    moreLink: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    style: PropTypes.object,
};

export default function HeaderBreadcrumbs({ isDark, action, heading, moreLink = '' || [], style, ...other }) {
    return (
        <Box style={{ mb: 5, ...style }}>
            <Box style={{ display: 'flex', alignItems: 'center' }}>
                <Box style={{ flexGrow: 1 }}>
                    <Typography variant="h5" gutterBottom style={{ color: isDark ? '#FFF' : '#000' }}>
                        {heading}
                    </Typography>
                </Box>

                {action && <Box style={{ flexShrink: 0 }}>{action}</Box>}
            </Box>

            <Box style={{ mt: 2 }}>
                {isString(moreLink) ? (
                    <Link href={moreLink} target="_blank" rel="noopener" variant="body2">
                        {moreLink}
                    </Link>
                ) : (
                    moreLink.map((href) => (
                        <Link
                            noWrap
                            key={href}
                            href={href}
                            variant="body2"
                            target="_blank"
                            rel="noopener"
                            style={{ display: 'table' }}
                        >
                            {href}
                        </Link>
                    ))
                )}
            </Box>
        </Box>
    );
}
